#orcamentosAlterar{
    overflow: hidden;
    .modalLoaderErro{
        top: 80px;
        position: absolute;
        left: 0;
        width: 100%;
        height: calc(100vh - 80px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,.15);
        z-index: 10;
        transition: all 0.5s ease;
        z-index: -1;
        .content{
            width: 300px;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .loader{
                border: 0px solid transparent;
                height: 100px;
                width: 100px;
                border-top: 3px solid #30c5ff;
                border-right: 3px solid transparent;
                border-radius: 50%;
                animation: girar 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
            }
            @keyframes girar{
                to{
                    transform: rotate(360deg);
                }
            }
        }
    }
    iframe{
        width: 100%;
        // PLV 4334 - Felipe - INICIO
        height: calc(100vh - 85px); //II-70 - INICIO/FIM
        // PLV 4334 - Felipe - FINAL 
        opacity: 0;
        visibility: hidden;
        transition: all 0.8s ease;
        &.on{
            opacity: 1;
            visibility: visible;
        }
    }
}